import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BlockCustomerStrateBasicSlider {
    constructor() {
        if ($('[data-element="block-strate-basic-slider"]').length > 0) {
            BlockCustomerStrateBasicSlider.buildSwiper()
        }
    }
    static async buildSwiper() {
        const element = $('[data-element="block-strate-basic-slider"]')
        let countElements = 0
        if (
            element.length > 0 &&
            !element.is(':hidden') &&
            !$('body').hasClass('eco-mode-active')
        ) {
            element.each(async function (index) {
                const slider = $(this).find('.block__customer__strate-basic-slider__slides')
                slider.attr('data-swiper', `strate-basic-slider-swiper_${countElements}`)
                countElements = countElements + 1
                const swiperKey = slider.data('swiper')

                const arrowLeft = $(this).find('.swiper-strate-basic-slider-swiper-prev')
                arrowLeft.attr('id', `swiper-${swiperKey}-prev`)
                const arrowRight = $(this).find('.swiper-strate-basic-slider-swiper-next')
                arrowRight.attr('id', `swiper-${swiperKey}-next`)

                const slideItem = `[data-swiper="${swiperKey}"] > .block__customer__strate-basic-slider__slide`

                const options = {
                    mousewheel: {
                        forceToAxis: true,
                    },
                    slidesPerView: 2,
                    spaceBetween: 10,
                    speed: 1000,
                    navigation: {
                        nextEl: `#swiper-${swiperKey}-next`,
                        prevEl: `#swiper-${swiperKey}-prev`,
                    },
                    keyboard: true,
                    loop: false,
                    freeMode: {
                        enabled: true,
                    },
                    freeModeFluid: true,
                    passiveListeners: false,
                    watchSlidesVisibility: true,
                    breakpoints: {
                        600: {
                            slidesPerView: 2.5,
                            spaceBetween: 20,
                        },
                        1000: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                    },
                }

                $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

                const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
            })
        }
    }
}

new BlockCustomerStrateBasicSlider()
